import { groupTransactionsByDate } from '@dltru/dfa-ui'
import { createSelector } from '@reduxjs/toolkit'

import IAppState from '../states'

const selectAccount = (state: IAppState) => state.moneyAccounts.account
const selectActiveMoney = (state: IAppState) => state.moneyAccounts.account?.balance?.available
const selectUserId = (state: IAppState) => state.moneyAccounts.account?.user_uuid
const selectTransactionsData = (state: IAppState) => state.moneyAccounts.transactions ?? []
const selectTransactions = createSelector(
    selectTransactionsData,
    selectUserId,
    groupTransactionsByDate,
)
const selectReserves = (state: IAppState) => state.moneyAccounts.reserves

export const moneySelector = {
    selectAccount,
    selectTransactions,
    selectReserves,
    selectActiveMoney,
}

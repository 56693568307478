import { MoneyAccountType, TransactionType } from '@dltru/dfa-models'
import { createAction, createSlice } from '@reduxjs/toolkit'

type ReserveDetails = {
    uuid: string
    amount: number
    status: string
    account_uuid: string
    service_code: string
}
export type AccountReserves = {
    sum: number
    reserves: ReserveDetails[] | null
}
interface IInitialState {
    error: string
    isLoading: boolean
    account: MoneyAccountType | null
    transactions: TransactionType[]
    reserves: AccountReserves | null
}

const initialState: IInitialState = {
    error: '',
    isLoading: false,
    account: null,
    transactions: [],
    reserves: null,
}

const moneyAccountSlice = createSlice({
    name: 'moneyAccounts',
    initialState,
    reducers: {
        getAccountMoney: (state) => {
            return state
        },
        updateAccountMoney(state, action) {
            state.account = action.payload
            state.isLoading = false
            state.error = ''
        },
        setLoaderAccountMoney(state, action) {
            state.isLoading = action.payload
        },
        dropAccountMoney() {
            return { ...initialState }
        },
        setAccountMoneyError(state, action) {
            state.error = action.payload
            state.isLoading = false
        },
        setTransaction(state, action) {
            state.transactions = action.payload
        },
        setReserves(state, action) {
            state.reserves = action.payload
        },
    },
})

export const loadsetAccountMoneyByUser = createAction('moneyAccounts/loadsetAccountMoneyByUser')
export const loadsetTransactionsByAccount = createAction<string>(
    'moneyAccounts/loadsetTransactionsByAccount',
)
export const loadsetReservesByAccount = createAction<string>(
    'moneyAccounts/loadsetReservesByAccount',
)
type TransactionDebitType = {
    amount: number
    purpose?: string
}
export const sendTransactionDebit = createAction<TransactionDebitType>(
    'moneyAccounts/sendTransactionDebit',
)
export const {
    getAccountMoney,
    updateAccountMoney,
    setLoaderAccountMoney,
    dropAccountMoney,
    setAccountMoneyError,
    setTransaction,
    setReserves,
} = moneyAccountSlice.actions
export default moneyAccountSlice.reducer

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import auth from './auth'
import certificates from './certificates'
import dfaAvailableDeals from './dfa/availableDeals'
import dfaDealDetail from './dfa/deal'
import dfaDealsList from './dfa/deals'
import dfaDetails from './dfa/details'
import dfaList from './dfa/list'
import dfaOwnersList from './dfa/owners'
import dfaOwnershipTransfer from './dfa/ownershipTransfer'
import dfaRates from './dfa/rates'
import documents from './documents'
import earlyRepayment from './earlyRepayment'
import moneyAccounts from './money'
import orderDetails from './orders/details'
import orderList from './orders/list'
import transferOrderList from './orders/transfer'
import paymentsScheduler from './paymentsScheduler'
import profileDetails from './profiles/details'
import qualifyStatement from './qualifyStatement'
import register from './register'
import roleForms from './roleForms'
import sagas from './sagas'
import secondaryDfaDealsList from './secondaryDfa/deals'
import secondaryDfaDetails from './secondaryDfa/details'
import secondaryDfaList from './secondaryDfa/list'
import tariff from './tariff'
import taxOperations from './tax/operations'
import taxTaxes from './tax/taxes'
import ui from './ui'
import walletUserAssetsList from './wallet/assets'
import walletEncumbrance from './wallet/encumbrance'
import walletUserHistoryList from './wallet/history'
import walletLocks from './wallet/locks'
import transactionDetails from './sign'

const sagaMiddleware = createSagaMiddleware()

const reducer = combineReducers({
    auth,
    register,
    dfaDetails,
    orderList,
    dfaDealsList,
    dfaOwnersList,
    dfaOwnershipTransfer,
    dfaList,
    orderDetails,
    secondaryDfaList,
    secondaryDfaDealsList,
    secondaryDfaDetails,
    dfaAvailableDeals,
    dfaDealDetail,
    roleForms,
    profileDetails,
    walletUserAssetsList,
    walletUserHistoryList,
    walletEncumbrance,
    moneyAccounts,
    ui,
    transferOrderList,
    walletLocks,
    certificates,
    documents,
    qualifyStatement,
    tariff,
    paymentsScheduler,
    earlyRepayment,
    dfaRates,
    taxTaxes,
    taxOperations,
    transactionDetails,
})

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
})

sagaMiddleware.run(sagas)

export type AppStore = typeof store
export type AppState = ReturnType<AppStore['getState']>
export default store

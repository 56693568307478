import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { TransactionType } from '@dltru/dfa-models'
import { openMessage, setSender } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { moneySelector } from '@store/money/selectors'

import api from '@services/api'

import {
    loadsetAccountMoneyByUser,
    loadsetReservesByAccount,
    loadsetTransactionsByAccount,
    sendTransactionDebit,
    setAccountMoneyError,
    setLoaderAccountMoney,
    setReserves,
    setTransaction,
    updateAccountMoney,
} from './index'

function* handleLoadAccounts() {
    try {
        const uuid = yield* select(authSelector.selectUserUid)

        if (uuid) {
            yield* put(setLoaderAccountMoney(true))
            const { data, error } = yield api.lib.getMoneyAccounts(uuid)

            if (error) {
                throw error
            }

            if (!data.items?.length) {
                //yield* put(updateAccountMoney({}))
                // throw new Error('no-money') ???
            } else {
                const { data: account, error } = yield api.lib.getMoneyAccountsBalance(
                    data.items[0].id,
                )

                if (error) {
                    throw error
                }

                yield* put(updateAccountMoney({ ...data.items[0], ...account }))
            }
        }
    } catch (error) {
        yield* put(setAccountMoneyError(String(error)))
        openMessage({
            type: 'error',
            message: 'Не удалось получить информацию о кошельке',
        })
    }
}

function* handleLoadTransactions({ payload }: ReturnType<typeof loadsetTransactionsByAccount>) {
    try {
        const uuid = yield* select(authSelector.selectUserUid)
        yield* put(setLoaderAccountMoney(true))

        const { data, error } = yield* call(api.lib.getTransactions, {
            order: 'asc(created_at)' as const,
            account_id: payload,
            limit: 9000, // временное решение https://dltru.atlassian.net/browse/DFA-2676 })
        })

        if (error) {
            throw error
        }

        if (data?.items) {
            data.items = data.items
                .filter((v: TransactionType) => {
                    if (v.type === 'payment' && v.recipient_uuid === uuid) {
                        return false
                    }
                    return true
                })
                .map((v: TransactionType) => ({
                    ...v,
                    sender_uuid: setSender(v.sender_uuid),
                }))

            yield* put(setTransaction(data.items))
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось получить список транзакций',
        })
        yield* put(setAccountMoneyError(error))
    } finally {
        yield* put(setLoaderAccountMoney(false))
    }
}

function* handleLoadReserves({ payload }: ReturnType<typeof loadsetReservesByAccount>) {
    try {
        yield* put(setLoaderAccountMoney(true))

        const { data, error } = yield* call(api.lib.getReserves, {
            order: 'desc(id)',
            account_id: [payload],
            limit: 9000,
        })

        if (error) {
            throw error
        }

        yield* put(setReserves(data.item))
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось получить сумму зарезервированных средств',
        })
        yield* put(setAccountMoneyError(error))
    } finally {
        yield* put(setLoaderAccountMoney(false))
    }
}
function* handleTransactionDebit({ payload }: ReturnType<typeof sendTransactionDebit>) {
    try {
        const account = yield* select(moneySelector.selectAccount)
        const userId = yield* select(authSelector.selectUserUid)
        if (account) {
            yield* put(setLoaderAccountMoney(true))
            const { error: sendDebitError } = yield* call(api.lib.sendDebit, {
                user_id: userId,
                account_id: account.id,
                amount: payload.amount,
                purpose: payload.purpose,
            })

            if (sendDebitError) {
                throw sendDebitError
            }
            yield* put(loadsetAccountMoneyByUser())
            yield* put(loadsetTransactionsByAccount(account.id))
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось выполнить списание',
        })
        yield* put(setAccountMoneyError(error))
    } finally {
        yield* put(setLoaderAccountMoney(false))
    }
}

export function* watchMoneySagas(): Generator<StrictEffect> {
    yield* takeLatest(loadsetAccountMoneyByUser.type, handleLoadAccounts)
    yield* takeLatest(loadsetTransactionsByAccount.type, handleLoadTransactions)
    yield* takeLatest(loadsetReservesByAccount.type, handleLoadReserves)
    yield* takeLatest(sendTransactionDebit.type, handleTransactionDebit)
}
